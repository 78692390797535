<template>
  <div class="mx-auto my-6">
    <h2 class="my-4">Datos Personales</h2>
    <v-row justify="center" class="px-2 mx-4 my-6">
      <v-card class="px-4 py-4" max-width="800">
        <p class="text-primary">
          Modificar Datos Personales:
        </p>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-row>
            <v-col cols="12" md="3">
              <v-text-field
                v-model="currentPersonalData.primer_nombre"
                label="Primer Nombre"
                id="IdCaja1"
                :rules="[rules.required]"
              ></v-text-field>
            </v-col>
            <v-col v-if="show" cols="12" md="3">
              <v-text-field
                v-model="currentPersonalData.segundo_nombre"
                id="IdCaja2"
                label="Segundo Nombre"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="3">
              <v-text-field
                v-model="currentPersonalData.apellido_paterno"
                label="Apellido Paterno"
                id="IdCaja3"
                :rules="[rules.required]"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="3">
              <v-text-field
                v-model="currentPersonalData.apellido_materno"
                label="Apellido Materno"
                id="IdCaja4"
                :rules="[rules.required]"
              ></v-text-field>
            </v-col>
            <v-col v-if="show" cols="12" md="3">
              <v-layout row wrap>
                <v-menu
                  v-model="fromDateMenu"
                  :close-on-content-click="false"
                  :nude-rigth="40"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="fromDateDisp"
                      label="Fecha de Nacimiento"
                      prepend-icon="mdi-calendar"
                      id="IdCaja5"
                      readonly
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="currentPersonalData.fecha_nacimiento"
                    locale="en-in"
                    no-title
                    @input="fromDateMenu = false"
                  ></v-date-picker>
                </v-menu>
              </v-layout>
            </v-col>

            <v-col v-if="show" cols="12" md="3">
              <v-text-field
                v-model="currentPersonalData.edad"
                label="Edad"
                id="IdCaja6"
              ></v-text-field>
            </v-col>
            <v-col v-if="show" cols="12" md="3">
              <v-text-field
                v-model="currentPersonalData.numero_identidad_CURP"
                label="Número de Identidad"
                id="IdCaja7"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="3">
              <v-text-field
                v-model="currentPersonalData.email"
                label="Correo Electrónico"
                :rules="[rules.required, rules.email]"
                id="IdCaja8"
              ></v-text-field>
            </v-col>
            <v-col v-if="show" cols="12" md="3">
              <v-text-field
                v-model="currentPersonalData.telefono_casa"
                type="number"
                label="Teléfono Fijo"
                id="IdCaja9"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="3">
              <v-text-field
                v-model="currentPersonalData.telefono_movil"
                type="number"
                label="Teléfono Móvil"
                :rules="[rules.required]"
                id="IdCaja10"
              ></v-text-field>
            </v-col>
            <v-col v-if="userType === 'Distribuidor'" cols="12" md="3">
              <v-text-field
                v-model="currentPersonalData.nombre_distribuidor"
                label="Nombre de Distribuidor"
                :rules="[rules.required]"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn :disabled="!valid" color="primary" @click="senData">
            Actualizar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-row>
  </div>
</template>

<script>
import { ApiAuth } from '@/api_utils/axios-base'
import { validationMixin } from '@/mixins/formsValidations.js'
import { mapState, mapActions } from 'vuex'
import store from '@/store'

export default {
  mixins: [validationMixin],
  props: {
    show: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      currentPersonalData: {},
      fromDateMenu: false,
      valid: true,
      userType: "",
      emailExists:"",
    }
  },
  created() {
    this.getPersonalData()
  },
  computed: {
    ...mapState('usuario', ['userData']),
    fromDateDisp() {
      return this.currentPersonalData.fecha_nacimiento
    }
  },
  methods: {
    ...mapActions('usuario', ['logout']),
    async getPersonalData() {
      this.userType = this.userData.tipo_usuario
      if(this.userType === "Cliente"){
        try{
          let response = await ApiAuth.get(`/personas/api/cliente/?usuario_id=${this.userData.id}`)
          const responseData = await response.data

          if (responseData.length) {
            let datosPersonales = await responseData[0].datos_personales

            this.currentPersonalData = datosPersonales
          }

        } catch(error){
          console.error("error getting cliente data", error)
        }
      } else if(this.userType === "Distribuidor") {
        try{
          let response = await ApiAuth.get(`/personas/api/distribuidor/?usuario_id=${this.userData.id}`)
          let datosPersonales = await response.data[0]

          this.currentPersonalData = datosPersonales.datos_contacto
          this.currentPersonalData.nombre_distribuidor = datosPersonales.nombre_distribuidor
        } catch(error){
          console.error("error getting distribuidor data", error)
        }
      }
    },
    validarForm(){
      const  input_1 = document.getElementById('IdCaja1').value;
      const  input_2 = document.getElementById('IdCaja2').value;
      const  input_3 = document.getElementById('IdCaja3').value;
      const  input_4 = document.getElementById('IdCaja4').value;
      const  input_5 = document.getElementById('IdCaja5').value;
      const  input_6 = document.getElementById('IdCaja6').value;
      const  input_7 = document.getElementById('IdCaja7').value;
      const  input_8 = document.getElementById('IdCaja8').value;
      const  input_9 = document.getElementById('IdCaja9').value;
      const  input_10 = document.getElementById('IdCaja10').value;

      let cont = 0;

      if(input_1 == ""){

        cont++;
      }
      if(input_2 == ""){

        cont++;
      }
      if(input_3 == ""){

        cont++;
      }
      if(input_4 == ""){

        cont++;
      }
      if(input_5 == ""){

        cont++;
      }
      if(input_6 == ""){

        cont++;
      }
      if(input_7 == ""){

        cont++;
      }
      if(input_8 == ""){

        cont++;
      }
      if(input_9 == ""){

        cont++;
      }
      if(input_10 == ""){

        cont++;
      }
      if(cont == 0){

	      document.getElementById("btn").disabled = true;

      }else{

	      document.getElementById("btn").disabled = true;
        
      }
    
    },
    async senData() {
      if(!this.$refs.form.validate()) {
        console.error("validation form error")
        return
      } else {
        store.commit('setLoading', true)
        if(!this.show
          && this.userType === "Distribuidor") {
          try {
            this.currentPersonalData.id_usuario = this.userData.id
            //let response = await ApiAuth.post('/personas/crear-distribuidor-pb', this.currentPersonalData)
            let response = await ApiAuth.post('/personas/crear-cuenta-distribuidor', {
              id_distribuidor: this.userData.distribuidorId,
              email: this.currentPersonalData.email
            })
            const responseData = await response.data
           // console.log("se creo data distri??", responseData.url)
            //this.logout()
            window.open(responseData.url, "_self")
          } catch(error) {
            console.error("error creating datos personales", error)
          } finally {
            setTimeout(() => { store.commit('setLoading', false)}, 500)
          }
        } else {
          let response = await ApiAuth.put(`/personas/api/datos-personales/${this.currentPersonalData.id}/`,
              this.currentPersonalData
            )

          // console.log(response)
          setTimeout(() => { store.commit('setLoading', false)}, 500)
        }
      }
    },
    checkoutValidation() {
      //console.log("tratando de enviar val form")
    }
  }
}
</script>
